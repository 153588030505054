import { Box, IconButton } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { makeStyles } from '@mui/styles';

import theme from '../../theme/theme';

const useStyles = makeStyles({
  delete: {
    color: '#e48787',
  },

  fileInfo: {
    color: '#e3e3e3',
  },

  confirm: {
	  marginRight: 5,
	  color: theme.palette.secondary.light
  }
});

export type FileInfoProps = {
  file: File;
  onDelete: (file: File) => void;
};

const FileInfo = ({ file, onDelete }: FileInfoProps) => {
  const classes = useStyles();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <CheckCircleIcon className={classes.confirm}/>
      {file.name}
	  <IconButton
        aria-label="delete"
        className={classes.delete}
        size="small"
        onClick={() => onDelete(file)}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export default FileInfo;
