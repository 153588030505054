import { Typography } from '@mui/material';
import { Box } from '@mui/system';

const PageNotFound = (): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        color: 'black',
        background: 'white',
        padding: '10px',
      }}
    >
      <Typography>🚧 Diese Seite existiert leider nicht 🚧</Typography>
    </Box>
  );
};

export default PageNotFound;
