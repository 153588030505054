import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import JobContextProvider from './contexts/JobContext';
import theme from './theme/theme';
import App from './App';
import { reactPlugin } from './services/ApplicationInsightsService';

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <JobContextProvider>
          <SnackbarProvider maxSnack={3}>
            <App />
          </SnackbarProvider>
        </JobContextProvider>
      </AppInsightsContext.Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
