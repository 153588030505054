import React from 'react';
import { makeStyles } from '@mui/styles';

import theme from '../theme/theme';

const useStyles = makeStyles({
  videoResponsive: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    padding: '24px',
    [theme.breakpoints.down('md')]: {
      padding: '10px',
    },
  },
  iframe: {
    // left: 0,
    // top: 0,
    // height: '100%',
    // width: '100%',
    // position: 'absolute',
  },
});

const YoutubeEmbed = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.videoResponsive}>
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        className={classes.iframe}
        frameBorder="0"
        height="450"
        src="https://www.youtube.com/embed/AT9G0xrAL8k"
        title="YouTube video player"
        width="100%"
        allowFullScreen
      />
    </div>
  );
};

export default YoutubeEmbed;
