import { Box } from '@mui/system';

import ApplicationConfirmationImage from '../resources/applicationConfirmationImage.jpeg';
const ApplicationConfirmationPage = (): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#e3e3e3',
        margin: '3rem 0 5rem 0',
      }}
    >
      <img
        src={ApplicationConfirmationImage}
        alt="Foto von glücklichen PHATties"
        width="100%"
      />
    </Box>
  );
};

export default ApplicationConfirmationPage;
