import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

import BenefitsComponent from '../components/BenefitsList';

import {
  JobPageContentSection,
} from '../components/JobPageContentSection';
import ReactDOMServer from 'react-dom/server';

const PHATtesMiteinanderPage = (): JSX.Element => {
  
  const benefitsComponentString = ReactDOMServer.renderToString(<BenefitsComponent />);

  return (
    <Container
      maxWidth="md"
      // style={{ backgroundColor: '#fff', padding: '70px 70px 30px 70px' }}
      // removed padding because causes mobile problems
      style={{ backgroundColor: '#fff', textAlign: 'justify' }}
    >
      <>
        <JobPageContentSection
            textHtml={benefitsComponentString}
            title="Benefits"
          />
      </>
    </Container>
  );
};

export default PHATtesMiteinanderPage;
