import React from 'react';

const Nachhaltigkeit: React.FC = () => {
  return (
    <div>
      <p>
        Als Unternehmen verstehen wir uns als Mit-Gestalter von gutem Leben auf diesem Planeten. Daher haben wir uns bei PHAT gefragt, was wir konkret tun können, um das Thema Nachhaltigkeit aktiv voranzutreiben.
      </p>
      <p>
        Die Methode, die uns eine griffige, sichtbare und verständliche Übersicht bietet, heißt Hand- und Fußabdruck. Unser negativer Beitrag ist der PHATte Fußabdruck, unser positiver Beitrag ist der PHATte Handabdruck. Damit es aber nicht nur beim Erfassen von Zahlen bleibt, haben wir insgesamt sieben Handlungsfelder abgeleitet, die zu den Prozessen und Strukturen von PHAT passen und in denen sich Kolleg:innen themenspezifisch engagieren können.
      </p>
      <p>Hier ein kleiner Einblick in unser konkretes Handeln:</p>
      <ul>
        <li>Ein Drittel aller PHATties engagiert sich bereits im Bereich Nachhaltigkeit über die Handlungsfelder</li>
        <li>Wir beziehen unsere Energie aus erneuerbaren Quellen</li>
        <li>Beim Einkauf achten wir auf die Auswahl nachhaltiger Produkte und Dienstleistungen, Plastikmüll versuchen wir zu minimieren – das gilt auch für das Ausrichten von Events</li>
        <li>Für Geschäftsreisen nutzen wir Züge und öffentliche Verkehrsmittel statt Flieger und Auto</li>
        <li>Last but not least: Wir haben unseren eigenen Podcast ins Leben gerufen, in dem sich alles um Nachhaltigkeit dreht: CEO2-neutral – Der PHATe Podcast von und mit PHAT CONSULTING (mittlerweile haben wir bereits die hundertste Folge gefeiert!)</li>
      </ul>
      <p>
        Mehr dazu findest Du auf unserer <a href="https://www.phatconsulting.de/nachhaltigkeit/verantwortungsvoll-die-zukunft-gestalten/" target="_blank" rel="noopener noreferrer">Homepage</a>.
      </p>
    </div>
  );
};

export default Nachhaltigkeit;
