import * as React from 'react';
import { useContext } from 'react';

import JobOfferingDto from '../model/jobOfferingDto';

type JobContextType = {
  jobOffering: JobOfferingDto | undefined;
  setJobOffering:
    | ((jobOffering: JobOfferingDto | undefined) => void)
    | undefined;
};

const JobContext = React.createContext<JobContextType>({
  jobOffering: undefined,
  setJobOffering: undefined,
});

export const useJobContext: () => JobContextType = () => useContext(JobContext);

const JobContextProvider: React.FC = ({ children }) => {
  const [jobOffering, setJobOffering] = React.useState<
    JobOfferingDto | undefined
  >(undefined);

  return (
    <JobContext.Provider
      value={{
        jobOffering,
        setJobOffering,
      }}
    >
      {children}
    </JobContext.Provider>
  );
};

export default JobContextProvider;
