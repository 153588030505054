import { useEffect, useState } from 'react';
import {
  Grid,
  styled,
  TextField,
  Container,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  CircularProgress,
  Box,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';
import emoStyled from '@emotion/styled';
import { Link, useHistory, useParams } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@mui/styles';

import receivedVia from '../model/receivedVia';
import FileUpload from '../components/upload/FileUpload';
import JobApplicationDto from '../model/jobApplicationDto';
import theme from '../theme/theme';
import httpLocal from '../http/httpLocal';
import httpFunction from '../http/httpFunction';

const useStyles = makeStyles({
  root: {
    marginTop: '1rem',
  },
  link: {
    color: '#000',
    textDecoration: 'none',
  },
  submitBtn: {
    textTransform: 'none',
    padding: '5 10',
    fontSize: '1rem',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    background: theme.palette.secondary.main,
    transition: '0.15s ease-in-out',
    '&:hover': {
      borderColor: theme.palette.secondary.light,
      background: theme.palette.secondary.light,
      color: theme.palette.primary.main,
    },
  },
  submitBtnNoHover: {
    textTransform: 'none',
    padding: '5 10',
    border: 'none',
    fontSize: '1rem',
    color: theme.palette.primary.main,
    background: '#fff',
  },
  circle: {
    background: 'white',
  },
});

const CustomTextField = styled(TextField)({
  '& label': {
    color: '#000',
  },
  '& .MuiInputBase-input': {
    color: '#000',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: '#000 !important',
  },
  '& .MuiSvgIcon-root': {
    color: '#000 !important',
  },
});
const ErrorMessage = styled('p')({
  color: '#d32f2f',
  fontSize: '0.75rem',
});

const CustomSelect = emoStyled(Select)`
  color: #000;
  :after {
    border-width: 1px;
    transform: scale(1) !important;
  }
`;

// validation schema
const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Pflichtfeld! Bitte ausfüllen.'),
  lastName: Yup.string().required('Pflichtfeld! Bitte ausfüllen.'),
  email: Yup.string()
    .email('Bitte eine gültige E-Mail Adresse verwenden')
    .required('Pflichtfeld! Bitte ausfüllen.'),
  mobile: Yup.number()
    .integer('Bitte kein Punkt und Komma ')
    .typeError('Bitte nur Zahlen eingeben'),
  privacyPolicyConsent: Yup.boolean().oneOf(
    [true],
    'Bitte stimmen Sie der Datenschutzerklärung zu',
  ),
  expectedSalary: Yup.string().matches(
    /^[0-9 ]*$/,
    'Bitte nur Zahlen und weder Punkt noch Komma verwenden',
  ),
  noticePeriod: Yup.string().matches(
    /^[0-9A-Za-z ]*$/,
    'Bitte nur Buchstaben und Zahlen verwenden',
  ),
});

const ApplicationPage = (): JSX.Element => {
  const [receivedVia, setReceivedVia] = useState<receivedVia[]>([]);
  const [open, setOpen] = useState(false);
  const [traceId, setTraceId] = useState('');

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const { offeringId } = useParams<{ offeringId: string }>();
  const history = useHistory();
  const classes = useStyles();

  const fetchChannelIdOptions = async () => {
    const channelIdResponse = await httpLocal.get<receivedVia[]>(
      '/channels.json',
    );
    setReceivedVia(channelIdResponse.data ?? []);
  };

  useEffect(() => {
    fetchChannelIdOptions();
  }, []);

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const handleSubmit = (applicationDto: JobApplicationDto) => {
    setIsUploading(true);

    const formData = new FormData();
    applicationDto.jobOfferingId = offeringId;

    for (const key in applicationDto) {
      const typedKey = key as keyof JobApplicationDto;

      if (typedKey && typedKey !== 'attachments') {
        formData.append(key, applicationDto[typedKey]?.toString() ?? '');
      } else {
        applicationDto.attachments?.forEach((file) => {
          formData.append('attachments', file);
        });
      }
    }

    httpFunction
      .post('api/createApplication', formData)
      .then(() => {
        setIsUploading(false);
        history.push('/applicationConfirmation');
      })
      .catch(({ response }) => {
        setIsUploading(false);
        setTraceId(response?.data?.traceId);
        setOpen(true);
      });
  };

  return (
    <LocalizationProvider>
      <Formik
        initialValues={{
          jobOfferingId: '',
          firstName: '',
          lastName: '',
          email: '',
          privacyPolicyConsent: false,
          applicantPoolConsent: false,
          channelId: '',
          mobile: '',
          expectedSalary: undefined,
          noticePeriod: '',
          attachments: [],
        }}
        validationSchema={validationSchema}
        onSubmit={(values: JobApplicationDto) => {
          handleSubmit(values);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <Container
              maxWidth="lg"
              style={{
                backgroundColor: '#fff',
                padding: '40px 90px 30px 90px',
              }}
            >
              <Grid className={classes.root} spacing={3} container>
                <Grid md={6} xs={12} item>
                  <Field
                    as={CustomTextField}
                    error={touched.firstName && errors.firstName}
                    id="firstname"
                    label="Vorname *"
                    name="firstName"
                    variant="standard"
                    fullWidth
                    // required
                  />
                  {errors.firstName && touched.firstName ? (
                    <ErrorMessage>{errors.firstName}</ErrorMessage>
                  ) : null}
                </Grid>
                <Grid md={6} xs={12} item>
                  <Field
                    as={CustomTextField}
                    error={touched.lastName && errors.lastName}
                    id="lastname"
                    label="Nachname *"
                    name="lastName"
                    variant="standard"
                    fullWidth
                  />
                  {errors.lastName && touched.lastName ? (
                    <ErrorMessage>{errors.lastName}</ErrorMessage>
                  ) : null}
                </Grid>
                <Grid md={6} xs={12} item>
                  <Field
                    as={CustomTextField}
                    error={touched.email && errors.email}
                    id="email"
                    label="E-Mail-Adresse *"
                    name="email"
                    variant="standard"
                    fullWidth
                  />
                  {errors.email && touched.email ? (
                    <ErrorMessage>{errors.email}</ErrorMessage>
                  ) : null}
                </Grid>
                <Grid md={6} xs={12} item>
                  {/* check if field is touched */}
                  <Field
                    as={CustomTextField}
                    error={touched.mobile && errors.mobile}
                    id="mobile"
                    label="Mobilnummer"
                    name="mobile"
                    variant="standard"
                    fullWidth
                  />
                  {errors.mobile && touched.mobile ? (
                    <ErrorMessage>{errors.mobile}</ErrorMessage>
                  ) : null}
                </Grid>
                <Grid md={6} xs={12} item>
                  <FormControl fullWidth>
                    <InputLabel
                      id="received-via-label"
                      sx={{
                        color: '#000',
                        transform: 'translate(0, 16px) scale(1)',
                        '&.MuiInputLabel-shrink': {
                          transform: 'translate(0, -9px) scale(0.75)',
                        },
                      }}
                    >
                      Gefunden auf
                    </InputLabel>
                    <Field
                      as={CustomSelect}
                      id="received-via-select"
                      label="Gefunden auf"
                      labelId="received-via-label"
                      name="channelId"
                      placeholder="Gefunden auf"
                      variant="standard"
                    >
                      {receivedVia.map((opt) => (
                        <MenuItem key={opt.ChannelId} value={opt.ChannelId}>
                          {opt.DisplayName}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid md={6} xs={12} item>
                  {/* check if field is touched */}
                  <Field
                    as={CustomTextField}
                    error={touched.noticePeriod && errors.noticePeriod}
                    id="noticePeriod"
                    label="Kündigungsfrist"
                    name="noticePeriod"
                    variant="standard"
                    fullWidth
                  />
                  {errors.noticePeriod && touched.noticePeriod ? (
                    <ErrorMessage>{errors.noticePeriod}</ErrorMessage>
                  ) : null}
                </Grid>
                <Grid md={6} xs={12} item>
                  <Field
                    as={CustomTextField}
                    error={touched.expectedSalary && errors.expectedSalary}
                    id="expectedSalary"
                    label="Wunschgehalt"
                    name="expectedSalary"
                    variant="standard"
                    fullWidth
                  />
                  {errors.expectedSalary && touched.expectedSalary ? (
                    <ErrorMessage>{errors.expectedSalary}</ErrorMessage>
                  ) : null}
                </Grid>

                <Grid md={12} xs={12} item>
                  <Field
                    component={FileUpload}
                    id="attachments"
                    label="Unterlagen"
                    name="attachments"
                    test="test"
                    variant="standard"
                    fullWidth
                  />
                </Grid>
                <Grid md={12} xs={12} item>
                  <FormGroup sx={{ color: '#000' }}>
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          name="applicantPoolConsent"
                          sx={{
                            color: '#000',
                            '&.Mui-checked': {
                              color: '#000',
                            },
                          }}
                        />
                      }
                      label={
                        <Grid container>
                          <Typography>
                            Gemäß der{' '}
                            <Link
                              className={classes.link}
                              target="_blank"
                              to="/declarationOfConsent"
                            >
                              {' '}
                              Einwilligungserklärung{' '}
                            </Link>{' '}
                            bin ich mit einer Speicherung meiner Daten im
                            Bewerberpool einverstanden.
                          </Typography>
                        </Grid>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          error={
                            touched.privacyPolicyConsent &&
                            errors.privacyPolicyConsent
                          }
                          name="privacyPolicyConsent"
                          sx={{
                            color: '#000',
                            '&.Mui-checked': {
                              color: '#000',
                            },
                          }}
                        />
                      }
                      // label="  "
                      label={
                        <Grid container>
                          <Typography>
                            Ich habe die{' '}
                            <a
                              className={classes.link}
                              href="https://www.phatconsulting.de/datenschutz/"
                              rel="noreferrer"
                              target="_blank"
                            >
                              {' '}
                              Datenschutzerklärung{' '}
                            </a>{' '}
                            zur Kenntnis genommen und stimme dieser zu.
                          </Typography>
                        </Grid>
                      }
                    />
                  </FormGroup>
                  {errors.privacyPolicyConsent &&
                  touched.privacyPolicyConsent ? (
                    <ErrorMessage>{errors.privacyPolicyConsent}</ErrorMessage>
                  ) : null}
                </Grid>
              </Grid>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                mt={3}
                p={2}
                sx={{ width: '100%' }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  mt={3}
                  p={2}
                  sx={{ width: '100%' }}
                >
                  <Button
                    className={
                      isUploading ? classes.submitBtnNoHover : classes.submitBtn
                    }
                    type="submit"
                    variant="outlined"
                  >
                    {isUploading ? (
                      <CircularProgress />
                    ) : (
                      'Bewerbung abschicken!'
                    )}
                  </Button>
                </Box>
                {isUploading ? (
                  <p style={{ textAlign: 'center' }}>
                    Bitte hab ein wenig Geduld. Wir verpacken Deine Bewerbung,
                    frankieren sie und verschicken diese. Dies kann einen
                    Augenblick dauern.
                  </p>
                ) : (
                  <></>
                )}
              </Box>
            </Container>
          </Form>
        )}
      </Formik>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
      >
        <Alert severity="error" variant="filled" onClose={handleClose}>
          Beim senden deiner Bewerbung ist leider ein Fehler aufgetreten. Bitte
          versuche es später erneut oder sag uns direkt Bescheid 👉{' '}
          <strong style={{ color: '#393939' }}>
            recruiting@phatconsulting.de
          </strong>
          . Richtig PHAT wäre es, wenn du uns diese{' '}
          <strong style={{ color: '#393939' }}>ID: {traceId} </strong> mit in
          die E-mail packst. Vielen Dank! 🙂
        </Alert>
      </Snackbar>
    </LocalizationProvider>
  );
};

export default ApplicationPage;
