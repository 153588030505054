import React from 'react';
import benefits_home_office from '../resources/benefits_home_office.png';
import benefits_fahrrad_tablet from '../resources/benefits_fahrrad_tablet.png';
import benefits_pirat from '../resources/benefits_pirat.png';
import benefits_location from '../resources/benefits_location.png';
import benefits_massage_sport from '../resources/benefits_massage_sport.png';
import benefits_bahn_fahren from '../resources/benefits_bahn_fahren.png';
import benefits_sabbatical from '../resources/benefits_sabbatical.png';
import benefits_spende from '../resources/benefits_spende.png';
import benefits_time from '../resources/benefits_time.png';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    quoteContainer: {
        display: 'flex',
        alignItems: 'center',  
        marginRight: '24px',  
        '@media (max-width: 1000px)': {
            marginRight: '4px',
            width: '100%'
        }    
    },
    quoteImage: {
        width: '155px',
        marginRight: '4px', // added some margin for spacing between image and text
        '@media (max-width: 1000px)': {
            marginLeft: '-12px', //this because on of the benefit icons (bahn), is reaching out to the left, can not cut
            marginRight: '4px',
            width: '80px'
        }
    },
    quoteContainerMargin: {
        marginTop: '20px', // added margin for spacing between different blocks
    },
});

const BenefitsComponent: React.FC = () => {
    const classes = useStyles();

    return (
        <>
            <p>
                Wir wollen, dass unsere Mitarbeiter:innen sich bei uns wohlfühlen. Deshalb haben wir über die Jahre viel Augenmerk auf PHATte Benefits gelegt. Eines gleich vorweg: Den großen Firmenwagen wirst Du bei uns nicht finden, dafür aber eine ganze Menge geldwerter Vorteile, die eine nachhaltige Mobilität, eine gesunde Work-Life-Balance, Deine berufliche und persönliche Entwicklung sowie eine gute Arbeitsatmosphäre unterstützen.
            </p>
 
            <p>
                Wichtige Werte von PHAT sind Vertrauen und Eigenverantwortung. Dies spiegelt sich auch in unseren Benefits wider. Nachfolgend wollen wir Dir daher ein paar unserer Benefits vorstellen, auf die wir besonders stolz sind, weil sie PHAT und unsere Arbeitsweise besonders und ausmachen:
            </p>

            <div className={classes.quoteContainer}>
                <img src={benefits_time} alt="Uhr" className={classes.quoteImage} />
                <div>
                    Wir bieten Dir Vertrauensarbeitszeit. Klar musst Du auch bei uns Deine Stunden buchen, da es gesetzlich vorgeschrieben ist, aber die Stunden kannst Du flexibel einplanen: Später Start, frühes Ende, langer Lunch oder nachmittags zum Kuchen zu Oma – alles möglich.
                </div>
            </div>

            <div className={`${classes.quoteContainer} ${classes.quoteContainerMargin}`}>
                <img src={benefits_home_office} alt="Home Office" className={classes.quoteImage} />
                <div>
                    Natürlich kannst Du bei uns regelmäßig im Home Office arbeiten und auch Workations sind nach Absprache möglich.
                </div>
            </div>

            <div className={`${classes.quoteContainer} ${classes.quoteContainerMargin}`}>
                <img src={benefits_sabbatical} alt="Auszeit" className={classes.quoteImage} />
                <div>
                    Längere Auszeit gewünscht? Wir bieten verschiedene Sabbatical-Modelle an.
                </div>
            </div>

            <div className={`${classes.quoteContainer} ${classes.quoteContainerMargin}`}>
                <img src={benefits_location} alt="Office" className={classes.quoteImage} />
                <div>
                    Unsere unschlagbare Location: Unser großzügiges Office befindet sich im Herzen von Hamburg, direkt auf dem Kiez.
                </div>
            </div>

            <div className={`${classes.quoteContainer} ${classes.quoteContainerMargin}`}>
                <img src={benefits_pirat} alt="St. Pauli Flagge" className={classes.quoteImage} />
                <div>
                    Im St. Pauli Stadion haben wir ein Separee, welches für Meetings genutzt werden kann, aber auch, um die Fußballspiele im Stadion zu sehen.
                </div>
            </div>

            <div className={`${classes.quoteContainer} ${classes.quoteContainerMargin}`}>
                <img src={benefits_massage_sport} alt="Massage Sport" className={classes.quoteImage} />
                <div>
                    Wir bieten regelmäßig Shiatsu-Behandlungen im Office an und eine vergünstigte Mitgliedschaft im Urban Sports Club.
                </div>
            </div>

            <div className={`${classes.quoteContainer} ${classes.quoteContainerMargin}`}>
                <img src={benefits_bahn_fahren} alt="Deutschlandticket" className={classes.quoteImage} />
                <div>
                    PHAT bezuschusst Dein Deutschlandticket, somit kannst Du für 29€/Monat die Öffis nutzen.
                </div>
            </div>

            <div className={`${classes.quoteContainer} ${classes.quoteContainerMargin}`}>
                <img src={benefits_spende} alt="Spenden" className={classes.quoteImage} />
                <div>
                    Wir spenden in Deinem Namen an eine wohltätige Organisation Deiner Wahl.
                </div>
            </div>

            <div className={`${classes.quoteContainer} ${classes.quoteContainerMargin}`}>
                <img src={benefits_fahrrad_tablet} alt="Corporate Benefits" className={classes.quoteImage} />
                <div>
                    Zusätzlich kannst Du von den Mitarbeiterangeboten von Corporate Benefits profitieren.
                </div>
            </div>
        </>
    );
}

export default BenefitsComponent;
