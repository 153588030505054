import React, { useEffect, useState } from 'react';
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory, Link } from 'react-router-dom';

import httpLocal from '../http/httpLocal';
import JobOfferingCard from '../components/JobOfferingCard';
import JobOfferingDto from '../model/jobOfferingDto';
import theme from '../theme/theme';
import ApplicationProcessBee from '../components/ApplicationProcessBee';

const useStyles = makeStyles(() => ({
  textblockDesktop: {
    marginTop: 50,
    color: '#fff',
  },
  textblockMobile: {
    marginTop: 0,
    color: '#fff',
  },
  headline: {
    textAlign: 'center',
    fontFamily: 'dinregular,Helvetica, Arial, sans-serif',
    fontSize: '2.2rem',
    lineHeight: 1,
    letterSpacing: 0,
  },
  text: {
    marginTop: 15,
    textAlign: 'justify',
    fontSize: '1.1rem',
    lineHeight: 1.2,
    letterSpacing: '-0.001em',
    fontFamily: 'dinregular,Helvetica, Arial, sans-serif',
  },
  directApplyBtn: {
    marginTop: 50,
    width: '80%',
    textTransform: 'none',
    padding: '5 10',
    fontSize: '1rem',
    fontWeight: 'normal',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    background: theme.palette.secondary.main,
    transition: '0.15s ease-in-out',
    '&:hover': {
      borderColor: theme.palette.secondary.light,
      background: theme.palette.secondary.light,
      color: theme.palette.primary.main,
    },
  },
  link: {
    color: '#97d700',
    textDecoration: 'none',
  },
}));

const JobOffers = (): JSX.Element => {
  const [jobOfferings, setJobOfferings] = useState<JobOfferingDto[]>([]);
  const { promiseInProgress } = usePromiseTracker({ delay: 500 });
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const fetchJobOfferings = async () => {
      const jobOffersResponse = await httpLocal.get<JobOfferingDto[]>(
        '/offerings.json',
      );
      setJobOfferings(jobOffersResponse.data ?? []);
    };
    trackPromise(fetchJobOfferings());
  }, []);

  const matches = useMediaQuery('(min-width:700px)');

  return (
    <Container>
      <Grid
        className={`${
          matches ? classes.textblockDesktop : classes.textblockMobile
        }`}
        item
      >
        <Typography className={classes.headline}>
          WILLKOMMEN IM KARRIEREPORTAL
        </Typography>
        <Typography className={classes.text}>
          Wir sind 89 Menschen, die zusammen die Digitalisierung rocken. Wir
          inspirieren und begleiten unsere Kunden auf dem Weg in eine digitale,
          nachhaltige und lebenswerte Welt von … MORGEN. Wir haben verstanden,
          dass wir nur durch Deine Entwicklung stärker für die gemeinsame
          Zukunft werden! Du musst wollen, wir machen Dich stark. Wirf
          <Link className={classes.link} to="/PHATtesMiteinander">
            {' '}
            hier{' '}
          </Link>
          gerne einen Blick hinter die Kulissen.
        </Typography>
      </Grid>
      {promiseInProgress ? (
        <Grid justifyContent="center" sx={{ marginTop: '40px' }} container>
          <CircularProgress color="primary" />
        </Grid>
      ) : (
        <Grid
          spacing={3}
          style={{ justifyContent: 'center', display: 'flex' }}
          container
        >
          {jobOfferings?.map((jobOffering) => (
            <JobOfferingCard
              key={jobOffering.OfferingId}
              jobOffering={jobOffering}
            />
          ))}
        </Grid>
      )}
      <Grid style={{ justifyContent: 'center' }} container>
        <Button
          className={classes.directApplyBtn}
          type="submit"
          variant="outlined"
          onClick={() => history.push('/application')}
        >
          <p style={{ margin: '0 auto' }}>
            Nichts passendes gefunden? Sende uns Deine{' '}
            <strong>Initiativbewerbung</strong>!
          </p>
        </Button>
      </Grid>

      <Box height="70px" />
    </Container>
  );
};

export default JobOffers;
