import { Box, Container, Typography } from '@mui/material';

type JobPageContentSectionTemplateProps = {
  title: string;
  textHtml: string;
  isIntroduction: boolean;
  introductionText: string;
  imgBase64: string;
};

const JobPageContentSectionTemplate = ({
  title,
  textHtml,
  isIntroduction,
  introductionText,
  imgBase64,
}: JobPageContentSectionTemplateProps): JSX.Element => {
  const backgroundColor = '#fff0';
  const textColor = '#000'; //theme.palette.secondary.main;

  return (
    <Box
      sx={{
        width: '100%',
        background: backgroundColor,
        padding: '25px 0px',
      }}
    >
      <Container maxWidth="md">
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {isIntroduction ? (
              <Typography
                sx={{
                  marginLeft: 0,
                  marginTop: '10px',
                  color: textColor,
                  fontSize: 'normal',
                }}
                textAlign="left"
              >
                {introductionText}
              </Typography>
            ) : (
              <></>
            )}

            <Typography
              sx={{
                marginLeft: 0,
                marginTop: '10px',
                color: textColor,
                fontFamily: 'Graviton',

                fontSize: '2.2rem',
              }}
              textAlign="left"
            >
              {title}
            </Typography>
          </Box>

          {isIntroduction ? (
            <img
              src={imgBase64}
              style={{
                objectFit: 'cover',
                aspectRatio: '1',
                margin: 'auto 0 auto auto',
                maxWidth: '200px',
              }}
            />
          ) : (
            <></>
          )}
        </Box>

        <Box
          dangerouslySetInnerHTML={{ __html: textHtml }}
          sx={{
            fontFamily: 'dinregular,Helvetica, Arial, sans-serif',
            marginTop: '1rem',
            color: textColor,
          }}
        />

        {/*
        <Box
          sx={{
            backgroundColor: backgroundColor,
          }}
        >
			
          <Typography
            sx={{
              marginTop: '10px',
              color: textColor,
              fontFamily: 'DIN',
              fontWeight: 'bolder',
              textTransform: 'uppercase',
			  fontSize: "larger"
            }}
            textAlign="left"
          >
            {title}
          </Typography>
		  
        </Box>

        <Box
          sx={{
            fontFamily: 'dinregular,Helvetica, Arial, sans-serif',
            marginTop: '1rem',
            color: textColor,
          }}
          dangerouslySetInnerHTML={{ __html: textHtml }}
        />
		*/}
      </Container>
    </Box>
  );
};

type JobPageIntroductionSectionProps = {
  title: string;
  textHtml: string;
  shortDescription: string;
  imgBase64?: string;
};

const JobPageIntroductionSection = ({
  title,
  textHtml,
  shortDescription,
  imgBase64,
}: JobPageIntroductionSectionProps): JSX.Element => {
  return (
    <JobPageContentSectionTemplate
      imgBase64={imgBase64!}
      introductionText={shortDescription}
      textHtml={textHtml}
      title={title}
      isIntroduction
    />
  );
};

type JobPageContentSectionProps = {
  title: string;
  textHtml: string;
};

const JobPageContentSection = ({
  title,
  textHtml,
}: JobPageContentSectionProps): JSX.Element => {
  return (
    <JobPageContentSectionTemplate
      imgBase64=""
      introductionText=""
      isIntroduction={false}
      textHtml={textHtml}
      title={title}
    />
  );
};

export { JobPageIntroductionSection, JobPageContentSection };
