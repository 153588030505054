import React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

const DeclatationOfConsent = (): JSX.Element => {
  return (
    <Box
      sx={{
        flexDirection: 'column',
        color: '#e3e3e3',
        margin: '3rem 0 5rem 0',
      }}
    >
      <Typography variant="h5">
        Einwilligungserklärung zur Speicherung von Bewerberdaten im Bewerberpool
      </Typography>
      <br />
      <Typography variant="subtitle1">
        Sollte meine Bewerbung nicht erfolgreich sein, willige ich ein, dass
        PHAT Consulting GmbH meine personenbezogenen Daten, die ich im Rahmen
        des gesamten Bewerbungsverfahrens mitgeteilt habe (zum Beispiel in
        Anschreiben, Lebenslauf, Zeugnissen, Fragebögen, Interviews), über das
        Ende des konkreten Bewerbungsverfahrens hinaus für die Dauer von
        höchstens 12 Monaten ab dem Absagedatum, speichert. Ich willige ein,
        dass PHAT diese Daten nutzt, um mich später zu kontaktieren und das
        Bewerbungsverfahren fortzusetzen, falls ich für eine andere Stelle in
        Betracht kommen sollte. Sofern ich in meinem Bewerbungsschreiben oder
        anderen von mir eingereichten Unterlagen „besondere Kategorien
        personenbezogener Daten“ nach Art. 9 der Datenschutz-Grundverordnung
        mitgeteilt habe (z. B. ein Foto, das die ethnische Herkunft erkennen
        lässt, Angaben über Schwerbehinderteneigenschaft, usw.), bezieht sich
        meine Einwilligung auch auf diese Daten. PHAT möchte allerdings alle
        BewerberInnen nur nach ihrer Qualifikation bewerten und bittet daher,
        auf solche Angaben in der Bewerbung möglichst zu verzichten. Meine
        Einwilligung ist freiwillig, ist für dieses Bewerbungsverfahren nicht
        nötig und hat auch keine Auswirkungen auf meine Chancen im jetzigen
        Bewerbungsverfahren. Ich kann sie ohne Angabe von Gründen verweigern,
        ohne dass ich deswegen Nachteile zu befürchten hätte. Ich kann meine
        Einwilligung zudem jederzeit zum Beispiel per Email an
        people@phatconsulting.de widerrufen.
      </Typography>
    </Box>
  );
};

export default DeclatationOfConsent;
