import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import FileInfo from './FileInfo';

const FileUpload = ({ ...props }): JSX.Element => {
  const [files, setFiles] = useState<File[]>([]);

  const snackbar = useSnackbar();

  useEffect(() => {
    props.form.setValues({
      ...props.form.values,
      attachments: files,
    });
  }, [files]);

  //Drop
  const onDrop = useCallback((accFiles: File[], rejFiles: FileRejection[]) => {
    if (rejFiles.length > 0) {
      snackbar.enqueueSnackbar(
        'Dateien müssen kleiner als 9MB und im PDF-Format sein.',
        {
          variant: 'error',
        },
      );
    }

    setFiles((curr) => [...curr, ...accFiles]);
  }, []);

  function duplicateValidator(file: File) {
    if (files.filter((exFile) => exFile.name === file.name).length > 0) {
      return {
        code: 'duplicate-file',
        message: `The file was already added.`,
      };
    }

    return null;
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: 'application/pdf',
    maxSize: 9 * 1024 * 1024,
    maxFiles: 7,
    validator: duplicateValidator,
  });

  const activeStyle = {
    borderColor: '#2196f3',
  };

  const acceptStyle = {
    borderColor: '#00e676',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
  };

  const onDelete = (file: File) => {
    setFiles((curr) => curr.filter((existingFile) => existingFile !== file));
  };

  const baseStyle = {
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#e3e3e3',
    borderStyle: 'dashed',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  return (
    <Grid md={12} item>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        {...getRootProps({ style })}
      >
        <input {...getInputProps()} />

        <p>
          Dateien (nur PDF, max. 9MB) per Drag and Drop hinzufügen oder hier
          klicken.
        </p>
      </Box>
      {files.map((file) => (
        <FileInfo key={file.name} file={file} onDelete={onDelete} />
      ))}
    </Grid>
  );
};

export default FileUpload;
