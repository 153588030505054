import * as React from 'react';
import {
  Typography,
  Container,
  Toolbar,
  useMediaQuery,
  Button,
  Menu,
} from '@mui/material';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';

import phatLogo from '../resources/phat_logo_header.svg';
import theme from '../theme/theme';
import { useState } from 'react';

const useStyles = makeStyles({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    marginBottom: 10,

    position: 'sticky',
  },
  toolbar: {
    padding: 0,
  },
  name: {
    fontFamily: 'Graviton',
    margin: '0 auto',
    fontSize: '1.4rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.8rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.4rem',
    },
  },
  NavElements: {
    color: '#fff',
    margin: '8px 0 0 14px',
    cursor: 'pointer',
    fontWeight: 'normal',
    fontSize: '1.1rem',
    '&:hover': {
      color: theme.palette.secondary.light,
    },
    fontFamily: 'DIN2014-Regular,RobotoRegular,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'
  },
  NavElementActive: {
    color: theme.palette.secondary.light,
  },
  // this is for the phat home text
  NavElementsExternal: {
    color: '#fff',
    margin: '8px 0 0 14px',
    cursor: 'pointer',
    fontWeight: 'normal',
    fontSize: '14px',
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  },
  BurgerNavElements: {
    color: '#fff',
    padding: '10px',
    cursor: 'pointer',
    fontWeight: 'normal',
    fontSize: '1.1rem',
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  },
  NavWrapper: {
    display: 'flex',
    justifyContent: 'right',
    width: '100%',
  },
  LinkStyle: {
    textDecoration: 'none',
  },
});

const StyledImg = styled.img`
  margin-top: 5px;
  padding: 7px 0 3px 0;
  height: 120px;
  object-fit: contain;
  margin-right: 40px;
`;

const Header = (): JSX.Element => {
  const [activeNavigationButton, setActiveNavigationButton] = useState<string>("");

  const classes = useStyles();
  const matches = useMediaQuery('(min-width:700px)');

  // For Burger Menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container maxWidth="md">
      <Toolbar className={classes.toolbar}>
        <Link style={{ marginTop: 25 }} to="/">
          <StyledImg src={phatLogo} />
        </Link>


        {matches ? (
  <Box display="flex" flexDirection="column" alignItems="flex-start" style={{marginLeft: 'auto' }}>
  {/* First Box for the PHATte WEBSEITE link */}
  <Box display="flex" justifyContent="flex-end" style={{marginLeft:'auto'}}>
    <Link
      className={classes.LinkStyle}
      rel="noreferrer"
      target="_blank"
      to={{ pathname: 'https://www.phatconsulting.de' }}
    >
      
      <Typography className={classes.NavElementsExternal}>
      <HomeIcon style={{ verticalAlign: "bottom", fontSize: "22px", marginRight: "3px" }} />
        PHAT HOME
      </Typography>
    </Link>
  </Box>
  <Box minHeight="40px"></Box>

  {/* Second Box for the rest of the navigation links */}
  <Box display="flex" justifyContent="flex-end" className={classes.NavWrapper}>
    <Link className={classes.LinkStyle} onClick={() => setActiveNavigationButton('jobs')} to="/">
      <Typography className={`${classes.NavElements} ${activeNavigationButton === 'jobs' ? classes.NavElementActive : ''}`} variant="h6">
        JOBS
      </Typography>
    </Link>
    <Link className={classes.LinkStyle} onClick={() => setActiveNavigationButton('aboutus')} to="/PHATtesMiteinander">
      <Typography className={`${classes.NavElements} ${activeNavigationButton === 'aboutus' ? classes.NavElementActive : ''}`} variant="h6">
        ÜBER UNS
      </Typography>
    </Link>
    <Link className={classes.LinkStyle} onClick={() => setActiveNavigationButton('benefits')} to="/Benefits">
      <Typography className={`${classes.NavElements} ${activeNavigationButton === 'benefits' ? classes.NavElementActive : ''}`} variant="h6">
        BENEFITS
      </Typography>
    </Link>
    {/* <Link className={classes.LinkStyle} onClick={() => setActiveNavigationButton('appliproc')} to="/ApplicationProcess">
      <Typography className={`${classes.NavElements} ${activeNavigationButton === 'appliproc' ? classes.NavElementActive : ''}`} variant="h6">
        TIPPS
      </Typography>
    </Link> */}
  </Box>
</Box>
        ) : (
          // Burger Menu
          <div className={classes.NavWrapper}>
            <Button
              aria-controls={open ? 'basic-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              id="basic-button"
              onClick={handleClick}
            >
              <MenuIcon />
            </Button>
            <Menu
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorEl={anchorEl}
              id="basic-menu"
              open={open}
              onClose={handleClose}
            >
              <Link className={classes.LinkStyle} to="/" onClick={handleClose}>
                <Typography className={classes.BurgerNavElements} variant="h6">
                  JOBS
                </Typography>
              </Link>
              <Link
                className={classes.LinkStyle}
                to="/PHATtesMiteinander"
                onClick={handleClose}
              >
                <Typography className={classes.BurgerNavElements} variant="h6">
                  ÜBER UNS
                </Typography>
              </Link>
              <Link
                className={classes.LinkStyle}
                to="/Benefits"
                onClick={handleClose}
              >
                <Typography className={classes.BurgerNavElements} variant="h6">
                  BENEFITS
                </Typography>
              </Link>
              {/* <Link
                className={classes.LinkStyle}
                rel="noreferrer"
                target="_blank"
                //add an external link with React Router
                to={{ pathname: 'https://www.phatconsulting.de' }}
                onClick={handleClose}
              >
                <Typography className={classes.BurgerNavElements} variant="h6">
                  PHATte WEBSEITE
                </Typography>
              </Link> */}
            </Menu>
          </div>
        )}

        {/* <div className={classes.NavWrapper}>
          <a href="/">
            <Typography className={classes.NavElements} variant="h6">
              JOBS
            </Typography>
          </a>
          <a href="/PHATtesMiteinander">
            <Typography className={classes.NavElements} variant="h6">
              PHATtes MITEINANDER
            </Typography>
          </a>
          <a
            href="https://www.phatconsulting.de"
            rel="noreferrer"
            target="_blank"
          >
            <Typography className={classes.NavElements} variant="h6">
              PHATte WEBSEITE
            </Typography>
          </a>
        </div> */}
      </Toolbar>
    </Container>
  );
};

export default Header;
