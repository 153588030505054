import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';

import JobOfferingDto from '../model/jobOfferingDto';
import { useJobContext } from '../contexts/JobContext';
import theme from '../theme/theme';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 50,
  },
  card: {
    background: '#fff',
    height: '100%',
    transition: '0.4s',
  },
  cardContent: {
    backgroundColor: '#fff',
    height: '100%',
    transition: '0.4s',
  },
  image: {
    aspectRatio: '2/1',
  },

  title: {
    color: '#000',
    fontFamily: 'Graviton',
    transition: '0.4s',
  },
  description: {
    color: '#000',
    transition: '0.4s',
  },
}));

type JobOfferingCardProps = {
  jobOffering?: JobOfferingDto;
};

const JobOfferingCard = ({
  jobOffering,
}: JobOfferingCardProps): JSX.Element => {
  const history = useHistory();
  const { setJobOffering } = useJobContext();
  const classes = useStyles();
  const [isHovering, setHovering] = useState(false);

  const handleOnClick = () => {
    if (setJobOffering && jobOffering) {
      setJobOffering(jobOffering);
    }
    history.push(`/jobs/${jobOffering?.Url}`);
  };

  return (
    <>
      <Grid className={classes.root} sm={6} xs={12} item>
        <Card
          className={classes.card}
          style={{ transform: isHovering ? 'scale3d(1.05, 1.05, 1)' : '' }}
          sx={{ maxWidth: 445 }}
        >
          <CardActionArea
            style={{ height: '100%' }}
            onClick={handleOnClick}
            onMouseEnter={() => {
              setHovering(true);
            }}
            onMouseLeave={() => {
              setHovering(false);
            }}
          >
            <CardMedia
              alt="developer"
              className={classes.image}
              component="img"
              //Stock image for local testing src="https://t4.ftcdn.net/jpg/02/61/31/83/360_F_261318391_vCfeZxtPmq1tCXFbPuuX0GkzutiVJKM5.jpg"
              image={jobOffering?.ThumbnailUrl}
            />
            <CardContent
              className={classes.cardContent}
              style={
                isHovering
                  ? {
                      backgroundColor: theme.palette.secondary.light,
                      color: '#fff',
                    }
                  : {}
              }
            >
              <Typography
                className={classes.title}
                component="div"
                style={isHovering ? { color: '#fff' } : {}}
                variant="h5"
                gutterBottom
              >
                {jobOffering?.Title}
              </Typography>
              <Typography
                className={classes.description}
                style={isHovering ? { color: '#fff' } : {}}
                variant="body2"
              >
                {jobOffering?.ShortDescription ?? ''}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </>
  );
};

export default JobOfferingCard;
