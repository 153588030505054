import JobOfferingDto from '../model/jobOfferingDto';

type JobStructuredDataProps = {
  jobOfferingDto: JobOfferingDto;
};

const JobStructuredData = ({
  jobOfferingDto,
}: JobStructuredDataProps): JSX.Element => {
  const jobOfferingStruturedData = {
    '@context': 'https://schema.org',
    '@type': 'JobPosting',
    title: jobOfferingDto?.Title,
    description:
      jobOfferingDto.IntroductionHtml +
      jobOfferingDto.TasksHtml +
      jobOfferingDto.RequirementsHtml +
      jobOfferingDto.OfferHtml,
    identifier: {
      '@type': 'PropertyValue',
      name: 'PHAT CONSULTING',
      value: jobOfferingDto.OfferingId,
    },
    datePosted: jobOfferingDto.DatePosted,
    hiringOrganization: {
      '@type': 'Organization',
      name: 'PHAT CONSULTING GmbH',
      sameAs: 'https://phatconsulting.de',
      logo:
        'https://www.phatconsulting.de/wp-content/themes/phatconsulting/img/phat-consulting-logo.png',
    },
    jobLocationType: 'TELECOMMUTE',
    employmentType: 'FULL_TIME',
    jobLocation: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Nobistor 10',
        addressLocality: 'Hamburg',
        addressRegion: 'Hamburg',
        postalCode: '22767',
        addressCountry: 'DE',
      },
    },
    applicantLocationRequirements: {
      '@type': 'Country',
      name: 'Germany',
    },
    directApply: true,
  };

  return (
    <script type="application/ld+json">
      {JSON.stringify(jobOfferingStruturedData)}
    </script>
  );
};

export default JobStructuredData;
