import { Container } from "@mui/material"
import ApplicationProcessBee from "../components/ApplicationProcessBee"

const ApplicationProcess = (): JSX.Element => {
    return (
    <Container maxWidth="md" style={{ backgroundColor: '#fff', textAlign: 'justify' }}>
        <ApplicationProcessBee></ApplicationProcessBee>
    </Container>
    );  
}

export default ApplicationProcess;