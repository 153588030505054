import { createTheme } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';

const theme = createTheme({
  palette: {
    primary: {
      main: '#e3e3e3',
    },
    secondary: {
      main: '#445964',
      light: '#97d700',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '1px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '1px',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          color: '#fff',
          backgroundColor: '#445964',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          background: '#121212',
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            // makes autofill background transparent
            transitionDelay: '9999s',
          },
        },
      },
    },

    MuiContainer: {
      styleOverrides: {
        root: {
          padding: 10,
        },
      },
    },
  },
});

export default theme;
