import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, CircularProgress, Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { Helmet } from 'react-helmet';

import httpLocal from '../http/httpLocal';
import {
  JobPageIntroductionSection,
  JobPageContentSection,
} from '../components/JobPageContentSection';
import JobStructuredData from '../components/JobStructuredData';
import theme from '../theme/theme';
import { useJobContext } from '../contexts/JobContext';
import JobOfferingDto from '../model/jobOfferingDto';

const useStyles = makeStyles({
  bottomNav: {
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    //position: 'sticky',
    //backgroundColor: "#fff",
    width: '100%',
    padding: '15px 0 40px 0',
  },
  applyBtn: {
    textTransform: 'none',
    padding: '5 10',
    fontSize: '1rem',
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    transition: '0.15s ease-in-out',
    '&:hover': {
      borderColor: theme.palette.secondary.light,
      background: theme.palette.secondary.light,
      color: theme.palette.primary.main,
    },
  },
  jopPageDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '-8px 0',
  },
});

const JobPage = (): JSX.Element => {
  const { offeringUrl } = useParams<{ offeringUrl: string }>();
  const history = useHistory();
  const classes = useStyles();
  const jobContext = useRef(useJobContext());
  const [jobOffering, setJobOffering] = useState<JobOfferingDto>();
  const { promiseInProgress } = usePromiseTracker({ delay: 500 });

  const fetchJobOffering = async () => {
    const jobOffersResponse = await httpLocal.get<JobOfferingDto[]>(
      '/offerings.json',
    );
    setJobOffering(
      jobOffersResponse.data.find((offer) => offer.Url === offeringUrl),
    );
  };

  useEffect(() => {
    if (jobContext.current.jobOffering)
      setJobOffering(jobContext.current.jobOffering);
    else trackPromise(fetchJobOffering());
  }, []);

  /*
  useEffect(() => {
	
    const fetchJobOfferings = async () => {
      const jobOffersResponse = await http.get<JobOfferingDto[]>(
        '/joboffering',
      );
      setJobOfferings(jobOffersResponse.data ?? []);
      isLoading = false;
    
	  const _jobOfferings = [
		{
		  offeringId: 'test',
		  thumbnailBase64: 'test',
		  title: 'code ninja',
		  shortDescription: 'Dies ist eine Kurzbeschreibung des JobOffers',
		  location: 'test',
		  introductionHtml: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur dictum facilisis mauris, sit amet ultrices justo elementum id. Maecenas tellus ipsum, sollicitudin a leo quis, volutpat fermentum mi. Aliquam erat volutpat. Pellentesque dignissim dapibus maximus. Aliquam sapien nisi, tempus varius augue a, faucibus finibus felis. Fusce id massa tincidunt, egestas. ',
		  tasksHtml: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur dictum facilisis mauris, sit amet ultrices justo elementum id. Maecenas tellus ipsum, sollicitudin a leo quis, volutpat fermentum mi. Aliquam erat volutpat. Pellentesque dignissim dapibus maximus. Aliquam sapien nisi, tempus varius augue a, faucibus finibus felis. Fusce id massa tincidunt, egestas. ',
		  requirementsHtml: 'test',
		  offerHtml: 'test',
		  datePosted: 'test',
		  url: 'test',
		},
		{
		  offeringId: 'test',
		  thumbnailBase64: 'test',
		  title: 'master of the universe',
		  shortDescription:
			'Dies ist eine Kurzbeschreibung des JobOffers',
		  location: 'test',
		  introductionHtml: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur dictum facilisis mauris, sit amet ultrices justo elementum id. Maecenas tellus ipsum, sollicitudin a leo quis, volutpat fermentum mi. Aliquam erat volutpat. Pellentesque dignissim dapibus maximus. Aliquam sapien nisi, tempus varius augue a, faucibus finibus felis. Fusce id massa tincidunt, egestas. ',
		  tasksHtml: 'test',
		  requirementsHtml: 'test',
		  offerHtml: 'test',
		  datePosted: 'test',
		  url: 'test',
		},
	  ];
	  setJobOffering(_jobOfferings[0]);
	  //return _jobOfferings;
    
    };
    trackPromise(fetchJobOfferings());
	
  }, []);
  */

  return (
    <Container maxWidth="md" style={{ backgroundColor: '#fff' }}>
      <Helmet>
        <title>{`${jobOffering?.Title} PHAT CONSULTING Job Portal`}</title>
        <meta content={jobOffering?.ShortDescription} name="description" />
        <meta content={jobOffering?.Title} property="og:title" />
        <meta
          content={jobOffering?.ShortDescription}
          property="og:description"
        />
        <meta content={jobOffering?.ThumbnailUrl} property="og:image" />
      </Helmet>
      {jobOffering ? (
        <>
          <JobStructuredData jobOfferingDto={jobOffering} />
          <div className={classes.jopPageDiv}>
            <div />

            <JobPageIntroductionSection
              // imgBase64={jobOffering?.thumbnailBase64 ?? ''}
              shortDescription={jobOffering?.ShortDescription ?? ''}
              textHtml={jobOffering?.IntroductionHtml ?? ''}
              title={jobOffering?.Title ?? ''}
            />
            <JobPageContentSection
              textHtml={jobOffering?.TasksHtml ?? ''}
              title="DEINE AUFGABEN"
            />
            <JobPageContentSection
              textHtml={jobOffering?.RequirementsHtml ?? ''}
              title="DEIN PROFIL"
            />
            <JobPageContentSection
              textHtml={jobOffering?.OfferHtml ?? ''}
              title="UNSER ANGEBOT"
            />
            <Box className={classes.bottomNav}>
              <Button
                className={classes.applyBtn}
                variant="outlined"
                onClick={() => {
                  if (
                    jobOffering?.OfferingId ===
                    'c6db1986-bc87-4393-bfc7-fe7af66da8f7'
                  ) {
                    // directs to jobmesse form
                    history.push(
                      `/jobmesseapplication/${jobOffering?.OfferingId}`,
                    );
                  } else {
                    // directs to normal form
                    history.push(`/application/${jobOffering?.OfferingId}`);
                  }
                }}
              >
                Jetzt bewerben!
              </Button>
            </Box>
          </div>
        </>
      ) : promiseInProgress ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#e3e3e3',
            margin: '3rem 0 5rem 0',
          }}
        >
          <Grid justifyContent="center" container>
            <CircularProgress color="primary" />
          </Grid>
        </Box>
      ) : (
        ''
      )}
    </Container>
  );
};

export default JobPage;
