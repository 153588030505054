import React, { useRef, useState, useCallback } from 'react';
import { Typography } from '@mui/material';
import bee_moving from '../resources/application_bee.png';
import flower_one from '../resources/application_flower1.png';
import flower_two from '../resources/application_flower2.png';
import flower_thr from '../resources/application_flower3.png';
import flower_fou from '../resources/application_flower4.png';
import flower_fiv from '../resources/application_flower5.png';


/**
 * `ApplicationProcessBee` Component
 * 
 * This component visualizes a path, a few stations along this path, and 
 * a bee (vehicle) that animates along this path when certain conditions 
 * (or user interactions) are met.
 * 
 * Note: This component uses hardcoded coordinates for simplicity, 
 * which might not be suitable for dynamic and responsive design.
 */
const ApplicationProcessBee: React.FC = () => {  
  const [targetFlowerState, setTargetFlowerState] = useState<string>("flower0");
  const [isFlying, setIsFlying] = useState<boolean>(false);
  
  // Vehicle's position state, the ref is needed in parallel for function execution (state is for rendering)
  const [vehiclePosition, setVehiclePosition] = useState<{x: number, y: number}>({x: -51, y: 242});
  const vehiclePositionRef = useRef<{x: number, y: number}>(vehiclePosition); 
  
  // Ref to reference the SVG path for coordinate calculations
  const pathRef = useRef<SVGPathElement>(null);

  const beeAnimationStyle = {
    position: 'absolute', 
    top: `${vehiclePosition.y}px`, 
    left: `${vehiclePosition.x}px`, 
    width: '400px', 
    height: '400px',
    background: `url(${bee_moving}) 0 0 no-repeat`,
    animation: isFlying ? 'beeAnimation  0.1s steps(3) infinite' : 'none',
    transform: 'scale(0.3)',
    transformOrigin: 'top left'
  } as React.CSSProperties;

  const divSurroundingBeeSvgStyle = {
    position: 'relative', width: '800px', marginLeft: '28px'
  } as React.CSSProperties;

  const flowerStyle = {
    cursor: 'pointer'
  } as React.CSSProperties;

  const flowerTextStyle = {
    fontWeight: 'bold',
    textAlign: "left"
  } as React.CSSProperties;

  const headLineStyles = {
    color: 'black',
    fontFamily: 'Graviton',
    marginLeft: 0,
    marginTop: '10px',
    position: 'absolute',
    left: '24px',
    top: '96px',
    fontSize: '2.2rem',
    textAlign: "left",
  } as React.CSSProperties;

  const mainDivStyle = {
    textAlign: 'center', position: 'relative', top: '-60px'
  } as React.CSSProperties;
  
  // Mapping of clicked flower along the path to associated text values
  const texts: { [key: string]: JSX.Element } = {
    flower0: (
      <p>
        Klicke auf die nummerierten Buttons, um zu erfahren, was im Bewerbungsprozess auf Dich zukommt und um Tipps für Deine Bewerbung zu erhalten.
      </p>
    ),  
    flower1: (
      <>
        <Typography sx={flowerTextStyle} >
          Step 1:<br/>Bewerbung absenden/Eingangsbestätigung
        </Typography>
        <p>
          Du hast Dich entschieden, Deine Bewerbung abzusenden? Wir freuen uns auf Dich!
          Gerne kannst Du Dich direkt auf eins der Stellenangebote bewerben oder Du sendest uns eine Initiativbewerbung. 
          Wenn Deine Bewerbung bei uns eingegangen ist, erhältst Du innerhalb der nächsten Tage eine persönliche Eingangsbestätigung.
        </p>
        <p><b>Tipp 1</b>: Ein Anschreiben ist kein Muss, wir freuen uns natürlich, wenn wir schon vorab ein paar Infos zu Dir erhalten (z.B. warum bewirbst Du Dich bei uns? Woher kennst Du uns? Was möchtest Du fachlich bei uns machen?). Quereinsteiger:innen und Junioren, aus deren Lebenslauf nicht sofort klar wird, was sie bei uns machen möchten, empfehlen wir ein Anschreiben.</p>
        <p><b>Tipp 2</b>: Wir duzen auf der Seite, daher kannst auch Du uns gerne duzen ;-)</p>
      </>
    ), 
    flower2: (
      <>
        <Typography sx={flowerTextStyle} >
          Step 2:<br/>Erstes Gespräch
        </Typography>
        <p>
          Nachdem Deine Bewerbung bei uns eingegangen ist, nehmen wir uns Zeit, diese zu sichten. Überzeugen Deine Unterlagen, erhältst Du Terminvorschläge für ein erstes Gespräch. Dieses findet oftmals remote statt und Du wirst zwei Mitglieder aus dem PEOPLE Team kennenlernen.
        </p>
        <p><b>Tipp 1</b>: Es geht darum, Dich kennenzulernen - sei so wie Du bist!</p>
        <p><b>Tipp 2</b>: Schau Dich doch vor dem Gespräch gerne noch mal auf unserer Karriereseite um. Dort findest Du z.B. das Video „Hinter den Kulissen“, was Dir einen guten Eindruck von PHAT geben sollte.</p>
      </>
    ), 
    flower3: (
      <>
        <Typography sx={flowerTextStyle} >
          Step 3:<br/>Zweites Gespräch
        </Typography>
        <p>
          Wenn das erste Gespräch für Dich und für uns ein Erfolg war, geht es in die zweite Runde. Hier wirst Du mit zwei Deiner direkten (zukünftigen) Kolleg:innen sprechen. In diesem Gespräch geht es mehr um fachliche Themen: Welche Aufgaben kommen auf Dich zu? Wie ist Dein aktueller Wissenstand? Wo willst Du bei uns wirken? Dieses Gespräch findet im Office statt, damit Du Dir einen noch besseren Eindruck von den PHATties und Deinem zukünftigen Arbeitsplatz machen kannst.
        </p>
        <p><b>Tipp 1</b>: Komm nur im Anzug, wenn das Dein alltäglicher Lieblings-Style ist. Wir sind eher locker unterwegs ;-)</p>
        <p><b>Tipp 2</b>: Bereite ein paar Fragen an die Kolleg:innen vor (z.B. wie laufen Projekte ab? Wie organisiert ihr euch?)</p>
      </>
    ), 
    flower4: (
      <>
        <Typography sx={flowerTextStyle} >
          Step 4:<br/>Konditionen
        </Typography>
        <p>
          Die Gespräche liefen gut, Du hast Bock und wir haben Bock, dann lass uns mal über Zahlen reden. Somit leiten wir die letzte Runde ein und Du verhandelst Deine Konditionen mit Lutz oder Janet aus dem PEOPLE Team. 
        </p>
      </>
    ), 
    flower5: (
      <>
        <Typography sx={flowerTextStyle} >
          Step 5:<br/>Arbeitsvertrag
        </Typography>
        <p>
          Als letztes folgt der Arbeitsvertrag und dann steht unserem gemeinsamen Glück nichts mehr im Wege. Diesen erhältst Du von Janet. 
        </p>
        <p>
          Dann können wir hoffentlich bald sagen: "Herzlich willkommen bei PHAT" :-)
        </p>
      </>
    ), 
  };

  // Hardcoded coordinates of station points along the path, indexed by string identifiers
  const hardcodedCoords: Record<string, {x: number, y: number}> = {
    'flower1': {x: 60, y: 179},
    'flower2': {x: 178, y: 146},
    'flower3': {x: 294, y: 122},
    'flower4': {x: 412, y: 96},
    'flower5': {x: 530, y: 62}
  };

  /**
   * `animateBee` - Animates the movement of a vehicle (bee) from a start position to an end position.
   * 
   * This function calculates the position of the vehicle (bee) for each frame during the animation,
   * interpolating linearly between the start and end positions over a specified duration. The vehicle's
   * position is updated and the animation continues until the end position is reached or the duration
   * elapses.
   * 
   * @param {number} startX - The starting x-coordinate for the vehicle's movement.
   * @param {number} startY - The starting y-coordinate for the vehicle's movement.
   * @param {number} endX - The ending x-coordinate for the vehicle's movement.
   * @param {number} endY - The ending y-coordinate for the vehicle's movement.
   * @param {number} startTime - The timestamp (in milliseconds) when the animation started.
   * 
   * Note: This function uses `requestAnimationFrame` for smooth animation and calls `setVehiclePosition`
   *       to update the vehicle's position in the state, and `vehiclePositionRef.current` for a ref-based
   *       position tracking. The animation stops when the vehicle reaches the end position or when the
   *       duration is completed.
   */
  const animateBee = (startX: number, startY: number, endX: number, endY: number, startTime: number) => {
    const duration = 1000; // Duration in milliseconds
    const frame = () => {
      const time = Date.now();
      const timeElapsed = time - startTime;
      const progress = Math.min(timeElapsed / duration, 1);
  
      const x = startX + (endX - startX) * progress;
      const y = startY + (endY - startY) * progress;
  
      setVehiclePosition({ x, y });
      vehiclePositionRef.current = { x, y};
  
      if (progress < 1) {
        requestAnimationFrame(frame);
      } else {
        setIsFlying(false); // Stop the flying animation when the movement is complete
      }
    };
  
    requestAnimationFrame(frame);
  };
  

  /**
   * `handleClick` - Event handler for user clicks on stations.
   * 
   * Extracts the position identifier from the clicked element and 
   * initiates the vehicle movement towards the clicked station.
   * 
   * @param {React.MouseEvent<SVGSVGElement, MouseEvent>} e - The click event object.
   */
  const handleClick = useCallback((e) => {
    const targetFlower = e.target.getAttribute('data-pos');

    // Safely check if the targetFlower is a valid key in hardcodedCoords
    if (!Object.prototype.hasOwnProperty.call(hardcodedCoords, targetFlower)) {
      // If not, return early
      return;
    }
    
    setIsFlying(true);
    setTargetFlowerState(targetFlower)
    const startTime = Date.now();
    const {x, y} = hardcodedCoords[targetFlower];
    animateBee(vehiclePositionRef.current.x, vehiclePositionRef.current.y, (x*1.33)-33, (y*1.33)-44, startTime)
  }, []);

  const keyframes = `
    @keyframes beeAnimation {
        100% {
          background-position: -1200px 0;
        }
    }`;

  return (
  <div style={mainDivStyle}>
    <Typography sx={headLineStyles}>Bewerbungsprozess</Typography>
    <style>
      {keyframes}
    </style>      
    <div style={divSurroundingBeeSvgStyle}>
      {/* Using React's onClick prop directly on the svg element */}
      <svg viewBox="0 0 600 280" className="vehicle-path" style={{overflow: 'visible', width: '100%'}} onClick={handleClick}>
          <path d="M0 250 C100 150 400 150 580 70" fill="none" stroke="#74B320" strokeWidth="3" stroke-dasharray="0.1,6" strokeLinecap="round" ref={pathRef}></path>
        
        <image href={flower_one} x={hardcodedCoords["flower1"].x} y={hardcodedCoords["flower1"].y} height="40" width="40" data-pos="flower1" style={flowerStyle}></image>
        <image href={flower_two} x={hardcodedCoords["flower2"].x} y={hardcodedCoords["flower2"].y} height="40" width="40" data-pos="flower2" style={flowerStyle}></image>
        <image href={flower_thr} x={hardcodedCoords["flower3"].x} y={hardcodedCoords["flower3"].y} height="40" width="40" data-pos="flower3" style={flowerStyle}></image>
        <image href={flower_fou} x={hardcodedCoords["flower4"].x} y={hardcodedCoords["flower4"].y} height="40" width="40" data-pos="flower4" style={flowerStyle}></image>
        <image href={flower_fiv} x={hardcodedCoords["flower5"].x} y={hardcodedCoords["flower5"].y} height="40" width="40" data-pos="flower5" style={flowerStyle}></image>
      </svg>
      <div  style={beeAnimationStyle}></div>
    </div>
    <div id="textContainer" style={{color: 'black', width: '100%', textAlign: 'left', fontFamily: 'dinregular,Helvetica,Arial,sans-serif'}}>
      {texts[`${targetFlowerState}`]}
    </div>
  </div>
  );
  
}

export default ApplicationProcessBee;
