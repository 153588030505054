import { Grid, Typography, Container, Link, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import theme from '../theme/theme';
import Icon_PhatP from '../resources/Footer_Icons/PHat-P.svg';
import {
  Email_Icon,
  Info_Icon,
  Instagram_Icon,
  Kununu_Icon,
  LinkedIn_Icon,
} from '../resources/Footer_Icons/Footer_Icons';

import LinkIconWrapper from './LinkIconWrapper';

const useStyles = makeStyles({
  footer: {
    color: '#fff',
    paddingBottom: 30,
    display: 'flex',
    justifyContent: 'center',
  },
  headline: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //marginBottom: "1em",
    fontFamily: 'Graviton',
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    marginTop: 0,
    justifyContent: 'center',
    fontFamily: 'dinregular,Helvetica, Arial, sans-serif',
    color: '#fff',
    '&:hover': {
      color: theme.palette.secondary.light,
      '& > *': {
        color: theme.palette.secondary.light,
      },
    },
  },
  text: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    marginTop: 0,
    fontFamily: 'dinregular,Helvetica, Arial, sans-serif',
    color: '#fff',
  },
  textIcon: {
    color: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bolder',
    fontFamily: 'Times New Roman',
  },
  icon: {
    height: '80%',
  },
  font: {
    marginBottom: 0,
  },
  IconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    marginRight: 10,
    width: '1em',
    height: '1em',
    color: theme.palette.secondary.main,
  },
  HomepageButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid white',
    padding: '0 5px 0 5px',
    transition: '0.15s ease-in-out',
    '&:hover': {
      borderColor: theme.palette.secondary.light,
      background: theme.palette.secondary.light,
      color: theme.palette.primary.main,
    },
  },
});

const WrappedIcon = (props: { icon: string | null }): JSX.Element => {
  const classes = useStyles();

  if (props.icon === null) {
    return (
      <div className={classes.IconWrapper} style={{ opacity: 0 }}>
        <div className={classes.icon} />
      </div>
    );
  }

  return (
    <div className={classes.IconWrapper}>
      <img src={props.icon} style={{ width: 20, height: 20 }} />
    </div>
  );
};

const Footer = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.footer} style={{ marginTop: 70 }}>
      <Container component="footer" maxWidth="md">
        <Typography className={classes.headline} variant="h3">
          Pairing Humans and Technology
        </Typography>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            margin: '0 10%',
          }}
        >
          {/* sec 1 */}
          <Grid md={4} sx={{ padding: '32px 16px 0 16px' }} xs={12} item>
            <Typography className={classes.font} variant="h6" gutterBottom>
              PHAT CONSULTING GmbH
            </Typography>
            <Typography className={classes.font}>
              <div className={classes.text} style={{ alignItems: 'normal' }}>
                <div style={{ marginTop: 3 }}>
                  <WrappedIcon icon={Icon_PhatP} />
                </div>
                Seit über 20 Jahren erarbeiten wir gemeinsam mit unseren Kunden
                individuelle Lösungen, um die Herausforderungen der IT und der
                Digitalisierung zu meistern.
              </div>
            </Typography>
          </Grid>
          {/* sec 2*/}
          <Grid sx={{ padding: '32px 16px 0 16px' }} item>
            <Typography className={classes.font} variant="h6" gutterBottom>
              Kontakt
            </Typography>

            <LinkIconWrapper
              icon={Email_Icon}
              linkText="recruiting@phatconsulting.de"
              linkURL="mailto:recruiting@phatconsulting.de"
            />
            {/*
             */}
            <LinkIconWrapper
              icon={Info_Icon}
              linkText="Datenschutzerklärung"
              linkURL="https://www.phatconsulting.de/datenschutz/"
            />

            <LinkIconWrapper
              icon={null}
              linkText="Einwilligungserklärung"
              linkURL="/declarationOfConsent"
            />

            <LinkIconWrapper
              icon={null}
              linkText="Impressum"
              linkURL="https://www.phatconsulting.de/impressum/"
            />
          </Grid>
          {/* sec 3*/}
          <Grid sx={{ padding: '32px 16px 0 16px' }} item>
            <Typography className={classes.font} variant="h6" gutterBottom>
              Folge uns
            </Typography>

            <LinkIconWrapper
              icon={LinkedIn_Icon}
              linkText="LinkedIn"
              linkURL="https://de.linkedin.com/company/phat-consulting-gmbh"
            />

            <LinkIconWrapper
              icon={Instagram_Icon}
              linkText="Instagram"
              linkURL="https://www.instagram.com/phatconsulting/?hl=de"
            />

            <LinkIconWrapper
              icon={Kununu_Icon}
              linkText="Kununu"
              linkURL="https://www.kununu.com/de/phat-consulting2"
            />
          </Grid>
        </div>
        <div
          style={{
            display: 'grid',
            justifyContent: 'center',
            gap: '20px',
            marginTop: 40,
          }}
        >
          <Typography variant="h6" gutterBottom>
            <Link
              className={classes.link}
              href="https://www.phatconsulting.de/"
              variant="subtitle1"
              style={{ margin: '0 auto' }}
            >
              <div className={classes.HomepageButton}>PHAT Webseite</div>
            </Link>
          </Typography>
          <Grid sm={12} xs={12} item>
            <Typography align="center" className={classes.font}>
              Copyright © {new Date().getFullYear()} PHAT CONSULTING
            </Typography>
          </Grid>
        </div>
      </Container>
    </Box>
  );
};

export default Footer;
