import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReactDOMServer from 'react-dom/server';

import PhatteStimmenComponent from '../components/PhatteStimmen';
import NachhaltigkeitComponent from '../components/Nachhaltigkeit';



import {
  JobPageIntroductionSection,
  JobPageContentSection,
} from '../components/JobPageContentSection';
import theme from '../theme/theme';
import YoutubeEmbed from '../components/YoutubeEmbed';

const useStyles = makeStyles({
  bottomNav: {
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: '15px 0 40px 0',
  },
  applyBtn: {
    textTransform: 'none',
    padding: '5px 10px',
    fontSize: '1rem',
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    transition: '0.15s ease-in-out',
    '&:hover': {
      borderColor: theme.palette.secondary.light,
      background: theme.palette.secondary.light,
      color: theme.palette.primary.main,
    },
  },
  jopPageDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '-8px 0',
  },
});
const PHATtesMiteinanderPage = (): JSX.Element => {
  const classes = useStyles();

  const WasMachtPHAT =
    'PHAT steht für „Pairing Humans And Technology“. Das ist unsere Mission und der Kern unserer Arbeit. Schwerpunkte unserer Beratungstätigkeit sind Veränderungsmanagement, Architektur und technische Umsetzung in Applikationen und Infrastruktur sowie das Anpassen von Organisations- und Betriebsmodellen. In den letzten Jahren ist noch ein weiteres wichtiges Feld hinzugekommen: das Thema Nachhaltigkeit. Wir begleiten unsere Kunden auf dem Weg in eine digitale, nachhaltige und lebenswerte Zukunft, und das auf typisch PHATte Art: mit viel Herz und Humor.';
  const PHATteKultur =
    'Was ist PHATtes Miteinander und PHATte Kultur? Auf die Frage gibt es wahrscheinlich so viele verschiedene Antworten, wie es PHATties gibt. Grundsätzlich verstehen wir darunter eine lebendige Unternehmenskultur, die sich durch flache Hierarchien, Selbstorganisation, Vielfalt und Familienfreundlichkeit auszeichnet. Und natürlich auch durch unsere legendären Teamevents und Aktivitäten, Freundschaft, wenig Politik und viel kreativen Freiraum.';

  const phatteStimmenString = ReactDOMServer.renderToString(<PhatteStimmenComponent />);
  const nachhaltigkeitString = ReactDOMServer.renderToString(<NachhaltigkeitComponent />);

  return (
    <Container
      maxWidth="md"
      // style={{ backgroundColor: '#fff', padding: '70px 70px 30px 70px' }}
      // removed padding because causes mobile problems
      style={{ backgroundColor: '#fff', textAlign: 'justify' }}
    >
      <>
        <div className={classes.jopPageDiv}>
          <JobPageIntroductionSection
            shortDescription="PHATtes Miteinander"
            textHtml={WasMachtPHAT}
            title="WAS MACHT PHAT?"
          />
          <YoutubeEmbed />
          <JobPageContentSection
            textHtml={phatteStimmenString}
            title="PHATte STIMMEN"
          />
          <JobPageContentSection
            textHtml={PHATteKultur}
            title="PHATte KULTUR"
          />
          <JobPageContentSection
            textHtml={nachhaltigkeitString}
            title="NACHHALTIGKEIT"
          />
        </div>
      </>
    </Container>
  );
};

export default PHATtesMiteinanderPage;
